import {
	Netzwerk,
	Vermarktung,
	Handshake,
	Transaction
} from "../components/Icons";

const Offer = (props: any) => {
	const smallerMarginTop = props.title === "Transaktionsbegleitung";
	return (
		<div className="col-span-1 flex flex-col">
			<div className="self-center">
				{props.icon}
			</div>

			<span className={(smallerMarginTop ? "mt-2" : "mt-4") +" text-xl text-center font-bold"}>{props.title}</span>
		</div>
	);
}

const AboutUs = () => {
	return (
		<div
			id="aboutus"
			className="min-h-screen bg-ds-gray-normal text-white flex flex-col p-6 sm:p-12 xl:px-28 2xl:px-44"
		>
			<h1 className="text-4xl md:text-5xl break-words text-ds-yellow text-center self-center mt-12 mb-16">
					Deutsch-Schweizerische Immobiliengesellschaft
			</h1>
			<div
				className="grid-cols-1 lg:grid-cols-5 2xl:grid-cols-10 gap-x-10 md:grid"
			>
				<div className="col-span-1 lg:col-span-3 2xl:col-span-5">
					<h2 className="mt-8 mb-12 text-4xl md:text-4xl font-semibold lg:mt-0 text-center lg:text-left">
						Über <span className="text-ds-yellow">uns.</span>
					</h2>

					Wir als DSI (Deutsch-Schweizerische Immobiliengesellschaft mbh) sind ein familiengeführtes Beratungsunternehmen
mit Sitz in Radebeul bei Dresden und in Luzern in der Schweiz.
					<br />
					<br />
					Unser Tätigkeitsfeld erstreckt sich von der klassischen Vermittlung,
bis hin zur Transaktionsbegleitung für Eigentümer, Projektentwickler & eigenkapitalstarke, institutionelle Investorengruppen, <br/>über die Assetklassen Healthcare, Wohnen, Büro & Logistik.
					<br />
					<br />
					Speziell haben wir den Fokus auf den Healthcare- & Wohnbereich mit betreuten Wohnanlagen,
jegliche Formen von Seniorenwohnen, stationären Pflegeheimen, Medizinischen Versorgungszentren sowie Ärztehäuser & Wohnanlagen/Wohnquartiere gelegt.
					<br />
					<br />
					Dabei treten wir als Initiator auf und bringen je nach Bedarf Eigentümer, Betreiber, Projektentwickler und den Endinvestor schon in einer frühen Phase zusammen.
					<br />
					Das Ziel ist es, einen effizienten Interessensausgleich zu erreichen, das Vorhaben aktiv zu begleiten und zum Erfolg zu führen.
					<br />
					<br />
					Unsere üblichen begleiteten Transaktionsvolumen bewegen sich zwischen 10&nbsp;bis&nbsp;100&nbsp;Mio.&nbsp;€/CHF.<br/>
					Im Einzelfall begleiten wir auch gern größere Transaktionen.
					<br />
					<br />
					Unser sorgfältig ausgesuchtes Team ist bekannt für Diskretion, Seriosität, Zuverlässigkeit und steht Ihnen gern für eine professionelle Beratung zur Verfügung.
					<br/>
					Erwarten Sie mehr von uns, als Sie bisher vom Immobilienmarkt kennen und stellen Sie uns und unsere Qualität auf die Probe.
				</div>
				<div className="col-span-1 items-center lg:col-span-2 2xl:col-span-2 flex flex-col mb-6">
					<h2 className="mt-8 text-4xl md:text-4xl font-semibold lg:mt-0 text-center">
						Was wir <span className="text-ds-yellow">bieten.</span>
					</h2>

					<div className="mt-12 grid grid-flow-row grid-col-1 sm:grid-cols-2 lg:grid-cols-1 gap-y-6">
						<Offer
							icon={<Transaction className="h-16 w-16 fill-ds-yellow" />}
							title="Transaktionsbegleitung"
						/>
						<Offer
							icon={<Vermarktung className="h-12 w-12 fill-ds-yellow" />}
							title="Global- & Einzelvertrieb"
						/>
						<Offer
							icon={<Handshake className="h-12 w-12 fill-ds-yellow" />}
							title="Partnerschaften & Kooperationen"
						/>
						<Offer
							icon={<Netzwerk className="h-12 w-12 fill-ds-yellow" />}
							title="Netzwerk"
						/>
					</div>
				</div>
				<div className="h-full hidden 2xl:block lg:col-span-3">
					<img
						className="h-full w-full object-cover"
						src="/assets/aboutus_handshake.jpg"
						alt="hands"
					/>
				</div>
			</div>
		</div>
	);
};

export default AboutUs;

