import Contact from "../sections/Contact";
import Hero from "../sections/Hero.jsx";
import Press from "../sections/Press";
import Reference from "../sections/References";
import AboutUs from "../sections/AboutUs";

import Footer from "../components/Footer";

const Landing = () => {
	return (
		<>
			<Hero />
			<AboutUs />
			<Reference />
			<Press />
			<Contact />
			<Footer />
		</>
	);
};

export default Landing;
