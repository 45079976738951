import DSGStore from "../DSGStore";

const PreviewImage = (props: any) => {
	return (
		<button
			onClick={props.clickHandler}
			className="group relative overflow-hidden"
		>
			<div className="h-full w-full brightness-[0.7] transition-all duration-300 group-hover:brightness-100">
				<img
					className="h-full w-full object-cover transition-all duration-[120s] ease-out group-hover:scale-[2]"
					src={props.url}
					alt={props.alt}
				/>
			</div>
			<h1 className="absolute top-6 left-4 transform text-left text-3xl text-white transition-all duration-1000 group-hover:translate-y-10 group-hover:opacity-0">
				{props.title}
			</h1>
			<div className="absolute top-[50%] left-[50%] flex h-20 w-20 -translate-y-[50%] -translate-x-[50%] scale-50 items-center justify-center rounded-full bg-black/60 opacity-0 transition-all duration-500 group-hover:scale-125 group-hover:opacity-100">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className=" h-12 w-12  text-white"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
					strokeWidth={2}
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7"
					/>
				</svg>
			</div>
		</button>
	);
};

const Reference = () => {
	const references = [
		{
			title: (
				<>
					Wohnquartier am
					<br />
					Wissenschaftsstandort&nbsp;Ost Dresden
				</>
			),
			clickable: true,
			previewImage: "/assets/references/0/0.jpg",
			previewImageAlt:
				"Wohnquartier am Wissenschaftsstandort Ost Dresden",
			images: [
				"/assets/references/0/0_1000x400.jpg",
				"/assets/references/0/1_1000x400.jpg",
				"/assets/references/0/2_1000x400.jpg",
			],
			points: [
				"Objektart: Grundstück inkl. Projektierung",
				"Transaktionsvolumen: kleiner zweistelliger Millionenbetrag",
				"Bauvolumen: mittlerer zweistelliger Millionenbetrag",
				"Verkauft: Q4 / 2021",
				"Tätigkeit: Transaktionsbegleitung",
			],
			additionalText: (
				<>
					Bei dem Vorhaben handelt es sich um ein rd. 8.700 m² großes
					Grundstück inkl. Projektierung, <br />
					zur Erstellung eines Wohnquartiers mit rd. 130 Wohneinheiten
					& möglicher Gewerbeeinheiten, <br />
					am neuen Wissenschaftsstandort Ost in Dresden.
					<br />
					<br />
					Das Quartier wird die erste Bebauung des neuen
					Wissenschaftsstandortes Ost und legt damit den Grundstein
					für eine weitere prosperierende Entwicklung der sächsischen
					Landeshauptstadt.
					<br />
					<br />
					Seit des Vermarktungsstartes im ersten Quartal 2020 wurde
					das Projekt von uns begleitet.
					<br />
					<br />
					Käufer ist ein institutioneller Investor aus dem bayrischen
					Raum.
				</>
			),
		},
		{
			title: "MVZ Marienberg",
			clickable: true,
			previewImage: "/assets/references/1/0.jpg",
			previewImageAlt: "Test",
			images: [
				"/assets/references/1/0_1000x400.jpg",
				"/assets/references/1/1_1000x400.jpg",
				"/assets/references/1/2_1000x400.jpg",
			],
			points: [
				"Objektart: Medizinisches Versorgungszentrum",
				"Transaktionsvolumen: kleinerer zweitstelliger Millionenbetrag",
				"Verkauft: Q4 / 2019",
				"Tätigkeit: Klassische Vermittlung, teils Transaktionsbegleitung",
			],
			additionalText: (
				<>
					Bei dem Objekt handelt es sich um ein Medizinisches
					Versorgungszentrum mit einer vermietbaren Fläche von ca.
					2.840 m² und einem 10 Jahre Betreibervertrag inkl.
					Verlängerungsoption von 5 Jahren, in der großen sächsischen
					Kreisstadt Marienberg.
					<br />
					<br />
					Zum Vermarktungsstart war das Objekt mit einer Erbpacht
					versehen,
					<br />
					die durch unser Mitwirken ausgeräumt werden konnte.
					<br />
					<br />
					Das Objekt wurde von einem großen institutionellen
					Healthcare-Investor <br />
					aus dem norddeutschen Raum erworben.
				</>
			),
		},
		{
			title: <>Wohnanlage im Großraum&nbsp;Dresden</>,
			clickable: true,
			previewImage: "/assets/references/2/1.jpg",
			previewImageAlt: "Paris",
			images: [
				"/assets/references/2/0_1000x400.jpg",
				"/assets/references/2/1_1000x400.jpg",
			],
			points: [
				"Objektart: Wohnanlage",
				"Transaktionsvolumen: kleinerer zweitstelliger Millionenbetrag",
				"Verkauft: Q2 / 2022",
				"Tätigkeit: Transaktionsbegleitung",
			],
			additionalText: (
				<>
					Bei diesem Vorhaben handelt es sich um eine im Bau
					befindliche Wohnanlage mit 48 Wohneinheiten im Großraum
					Dresden, in der großen Kreisstadt Freital.
					<br />
					<br />
					Durch das starke Wachstum der sächsischen Landeshauptstadt
					Dresden,
					<br />
					gewinnt Freital als unmittelbare Speckgürtelstadt zunehmend
					an Bedeutung und birgt ein großes Potenzial für die Zukunft.
					<br />
					<br />
					Von der Grundstücksbeschaffung bis zur Veräußerung an den
					Endkäufer
					<br />
					wurde das Vorhaben von uns begleitet.
					<br />
					<br />
					Käufer ist ein Gruppe Privatanleger aus den DACH-Staaten.
				</>
			),
		},
	];

	const handleOpen = (index: number) => {
		const clickedReference = references[index];

		DSGStore.update((state) => {
			state.referenceModalOpen = true;
			state.referenceModalTitle = clickedReference.title;
			state.referenceModalImages = clickedReference.images;
			state.referenceModalPoints = clickedReference.points;
			state.referenceModalAdditionalText =
				clickedReference.additionalText;
		});
	};

	return (
		<div
			className="flex min-h-screen w-full flex-col py-12 md:pt-16 md:pb-32"
			id="reference"
			style={{
				backgroundImage: `url("/assets/references_background.jpg")`,
				objectFit: "cover",
				backgroundSize: "cover",
			}}
		>
			<h1 className="mb-6 text-center text-4xl text-ds-yellow md:text-5xl">
				Auszug aus unseren Referenzen
			</h1>
			<div className="mt-8 grid flex-1 grid-cols-1 gap-2 md:gap-0 lg:grid-cols-3">
				{references.map((reference, index) => (
					<PreviewImage
						key={index}
						clickHandler={() => {
							if (reference.clickable) handleOpen(index);
						}}
						title={reference.title}
						url={reference.previewImage}
						alt={reference.previewImageAlt}
					/>
				))}
			</div>
		</div>
	);
};

export default Reference;
