import { Link } from "react-router-dom";
import { Stock } from "./Icons";

const Footer = () => {
	const showByAskrella = false

	return (
		<div className="relative flex items-start justify-between bg-ds-gray-dark pl-12 pr-12 pb-12 text-white md:pl-24 md:pb-12">
			<div className="z-10 flex w-full flex-col items-center justify-center md:flex-row md:justify-between">
				<div className="text-center md:text-left">
					<Link to="/legal-notice">Impressum</Link>
					&nbsp;|&nbsp;
					<Link to="/legal-notice">Datenschutz</Link>
					<p className="mt-1 text-xs">
						&copy;&nbsp;
						<span>{new Date().getFullYear()}</span>
						&nbsp;
						<span>DS Gesellschaft. Alle Rechte vorbehalten</span>
					</p>
				</div>
				<div>
					<p className={!showByAskrella ? `invisible` : ""}>
						Webdesign by&nbsp;
						<a className="text-ds-yellow" href="https://askrella.de" rel="noreferrer" target="_blank">askrella</a>
					</p>
				</div>
			</div>
			<Stock className="pointer-events-none absolute bottom-0 left-0" />
		</div>
	);
};

export default Footer;
