import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";

import Landing from "./pages/Landing";
import Imprint from "./pages/Imprint";
import LegalNotice from "./pages/LegalNotice";

import MessageModal from './modals/MessageModal'
import ReferenceModal from "./modals/ReferenceModal";
import PressModal from './modals/PressModal';

import DSGStore from "./DSGStore";

Sentry.init({
	dsn: "https://35ec1f0dfdf54601932bfe8db6d54b11@o1068296.ingest.sentry.io/6301776"
});

function App() {
	const state = DSGStore.useState();

	const handleMessageModalClose = () => {
		DSGStore.update(state => {
			state.messageModalOpen = false;
		})
	}

	const handleReferenceModalClose = () =>
		DSGStore.update(state => {
			state.referenceModalOpen = false;
		});

	const handlePressModalClose = () =>
		DSGStore.update(state => {
			state.pressModalOpen = false
		})

	return (
		<BrowserRouter>
			<MessageModal
				open={state.messageModalOpen}
				handleClose={handleMessageModalClose}
			/>
			<ReferenceModal
				open={state.referenceModalOpen}
				handleClose={handleReferenceModalClose}
			/>
			<PressModal
				open={state.pressModalOpen}
				handleClose={handlePressModalClose}
			/>
			<Routes>
				<Route path="/" element={<Landing />} />
				<Route path="/imprint" element={<Imprint />} />
				<Route path="/legal-notice" element={<LegalNotice />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
