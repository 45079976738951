import { Store } from "pullstate";

interface DSGStoreInterface {
	messageModalOpen: boolean;
	messageModalTitle: string | undefined
	messageModalMessage: string | undefined;

	referenceModalOpen: boolean;
	referenceModalTitle: any | undefined;
	referenceModalImages: string[];
	referenceModalPoints: (string | undefined)[];
	referenceModalPointsRight: (string | undefined)[];
	referenceModalAdditionalText: any;

	pressModalOpen: boolean;
	pressModalTitle: any | undefined;
	pressModalImages: string[];
	pressModalText: any | undefined;
}

const storeObject: DSGStoreInterface = {
	messageModalOpen: false,
	messageModalTitle: undefined,
	messageModalMessage: undefined,

	referenceModalOpen: false,
	referenceModalTitle: undefined,
	referenceModalImages: [],
	referenceModalPoints: [],
	referenceModalPointsRight: [],
	referenceModalAdditionalText: undefined,

	pressModalOpen: false,
	pressModalTitle: undefined,
	pressModalImages: [],
	pressModalText: undefined
}

const DSGStore = new Store(storeObject);

export default DSGStore;
