import Navigation from "../components/Navigation";

const LegalNotice = () => {
	return (
		<div 
			className="h-screen max-w-full flex flex-col overflow-x-hidden bg-ds-gray-normal text-white"
			style={{
				backgroundImage: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url("/assets/hero-section/0.jpg")`,
				objectFit: "cover",
				backgroundSize: "cover",
		}}>
			<Navigation />
			<div className="h-full px-8 pt-8">
				<h1 className="text-3xl font-europa-nuova-bold">Impressum</h1>

				<h2 className="text-xl font-europa-nuova-bold mt-8">Allgemeine Informationen (gemäß §5 TMG)</h2>
				<br/>
				<p>
					Deutsch-Schweizerische Gesellschaft<br />
					Maik Hösel<br />
					Schlösslihalde 27<br />
					6006 Luzern<br />
					Schweiz<br />
					Email: info@DS-Gesellschaft.ch<br />
					Verantwortlich für Inhalte: Maik Hösel<br />
				</p>

				<br/>
				<p>
					<span className="text-xl font-europa-nuova-bold mt-8">HAFTUNGSAUSSCHLUSS</span><br/>
					Der Autor übernimmt keinerlei Gewähr hinsichtlich der  inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und  Vollständigkeit der Informationen.<br/>
					Haftungsansprüche gegen den Autor wegen Schäden materieller  oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw.  Nichtnutzung der veröffentlichten Informationen,
					durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden  ausgeschlossen.<br/>
					Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile  der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen,
					zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.<br/><br/>
				</p>
				<p>
					<span className="text-xl font-europa-nuova-bold mt-8">HAFTUNG FÜR LINKS</span><br/>
					Verweise und Links auf Webseiten  Dritter liegen ausserhalb unseres Verantwortungsbereichs.<br/>
					Es wird jegliche Verantwortung für solche Webseiten  abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen  auf eigene Gefahr des Nutzers oder der Nutzerin.<br/><br/>
				</p>
				<p>
					<span className="text-xl font-europa-nuova-bold mt-8">URHEBERRECHTE</span><br/>
					Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf der Website gehören ausschliesslich der Firma Deutsch-Schweizerische Gesellschaft oder den
					speziell genannten  Rechtsinhabern. Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung der Urheberrechtsträger im Voraus einzuholen.<br/><br/>
				</p>
				<p>
					<span className="text-xl font-europa-nuova-bold mt-8">DATENSCHUTZ</span><br/>
					Gestützt auf Artikel 13 der schweizerischen Bundesverfassung  und die datenschutzrechtlichen Bestimmungen des Bundes (Datenschutzgesetz, DSG)  hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf Schutz vor  Missbrauch ihrer persönlichen Daten. Wir halten diese Bestimmungen ein.  Persönliche Daten werden streng vertraulich behandelt und weder an Dritte  verkauft noch weiter gegeben.<br/>
					In enger Zusammenarbeit mit unseren Hosting-Providern  bemühen wir uns, die Datenbanken so gut wie möglich vor fremden Zugriffen, Verlusten,  Missbrauch oder vor Fälschung zu schützen.<br/>
					Beim Zugriff auf unsere Webseiten werden folgende Daten in  Logfiles gespeichert: IP-Adresse, Datum, Uhrzeit, Browser-Anfrage und allg. übertragene Informationen zum Betriebssystem resp. Browser.
					Diese Nutzungsdaten bilden die Basis für statistische, anonyme Auswertungen, so dass Trends erkennbar sind, anhand derer wir unsere Angebote entsprechend verbessern  können.<br/><br/>
				</p>
			</div>
		</div>
	);
};

export default LegalNotice;
