import React from 'react';
import Navigation from "../components/Navigation";

// JSX
import HeroSlider, { Slide, OverlayContainer } from "hero-slider";

// Images

const images = [
	{ 
		image: "/assets/hero-section/0.jpg",
		text: <>
			<div className="animate-fade-in">
				<p>
					Wir begleiten <span className="font-semibold text-ds-yellow">Transaktionen</span>
				</p>
				<p>
					aus <span className="font-semibold text-ds-yellow">Leidenschaft.</span>
				</p>
			</div>
		</>
	},
	{
		image: "/assets/hero-section/1.jpg",
		text: <>
			<div className="animate-fade-in">
				<span className="font-semibold text-ds-yellow">Gemeinsam</span> die <span className="font-semibold text-ds-yellow">Zukunft</span> erschaffen.
			</div>
		</>		
	},
	{
		image: "/assets/hero-section/2.jpg",
		text: <>
			<div className="animate-fade-in">
				<span className="font-semibold text-ds-yellow">Nachhaltig, Erfolgreich, Zielführend.</span>
			</div>
		</>
	}
];
const Landing = () => {
	const [currentSlide, setCurrentSlide] = React.useState(0);

	return (
		<div
			className="h-screen max-w-full overflow-x-hidden"
		>
			<HeroSlider
				slidingAnimation="top_to_bottom"
				orientation="vertical"
				initialSlide={1}
				onAfterChange={(nextSlide) => {
					setCurrentSlide(nextSlide - 1)
				}}
				style={{ backgroundColor: "#000" }}
				settings={{
					slidingDuration: 1000, // How fast it slides the animation to the next image
					slidingDelay: 1200, // How long it stays on the current image
					autoplayDuration: 700,
					shouldAutoplay: true,
					shouldDisplayButtons: false,
					height: "100vh",
				}}
			>
				<OverlayContainer>
					<Navigation />
					<div className="absolute bottom-32 left-8 right-4 text-3xl text-white md:left-28">
						{/* This is a workaround, so the tailwind fade in animation is triggered */}
						{
							currentSlide === 0 && images[currentSlide].text
						}
						{
							currentSlide === 1 && images[currentSlide].text
						}
						{
							currentSlide === 2 && images[currentSlide].text
						}
					</div>
				</OverlayContainer>

				{
					images.map((item, index) =>
						<Slide
							key={index}
							shouldRenderMask
							background={{ backgroundImage: item.image }}
						/>
					)
				}
			</HeroSlider>
		</div>
	);
};

export default Landing;
