import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";

import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";

const navigation = {
	left: [
		{ name: "Über uns", href: "/#aboutus" },
		{ name: "Referenzen", href: "/#reference" },
	],
	right: [
		{ name: "Presse", href: "/#press" },
		{ name: "Kontakt", href: "/#contact" },
	]
}

const classNames = (...classes: string[]) => classes.filter(Boolean).join(" ");

const navigationItem = (item: any) =>
	<a className="transition duration-200 hover:text-ds-yellow" href={item.href} key={item.name}>{item.name}</a>;

const Navigation = () => {
	const [sidebarOpen, setSidebarOpen] = useState(false);

	useEffect(() => {
		smoothscroll.polyfill();
	});

	return (
		<>
			<Transition.Root show={sidebarOpen} as={Fragment}>
				<Dialog
					as="div"
					className="fixed inset-0 z-40 flex md:hidden"
					onClose={setSidebarOpen}
				>
					<Transition.Child
						as={Fragment}
						enter="transition-opacity ease-linear duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-opacity-75 backdrop-blur-sm backdrop-filter" />
					</Transition.Child>
					<Transition.Child
						as={Fragment}
						enter="transition ease-in-out duration-300 transform"
						enterFrom="-translate-x-full"
						enterTo="translate-x-0"
						leave="transition ease-in-out duration-300 transform"
						leaveFrom="translate-x-0"
						leaveTo="-translate-x-full"
					>
						<div className="relative flex w-full max-w-xs flex-1 flex-col bg-black">
							<Transition.Child
								as={Fragment}
								enter="ease-in-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in-out duration-300"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<div className="absolute top-0 right-0 mr-4 pt-4">
									<button
										type="button"
										className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ds-yellow"
										onClick={() => setSidebarOpen(false)}
									>
										<span className="sr-only">
											Close sidebar
										</span>
										<XIcon
											className="h-12 w-12 text-white"
											aria-hidden="true"
										/>
									</button>
								</div>
							</Transition.Child>
							<div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
								<nav className="mt-20 ml-12 space-y-8">
									{
										navigation.left.concat(navigation.right).map(item =>
											<Link
												key={item.name}
												reloadDocument
												to={item.href}
												className={classNames(
													"text-gray-300 hover:bg-gray-700 hover:text-white",
													"group flex items-center rounded-md px-2 py-2 text-2xl font-medium"
												)}
												onClick={() => setSidebarOpen(false)}
											>
												{item.name}
											</Link>
										)
									}
								</nav>
							</div>
							<div className="ml-12 mb-8 flex text-white">
								<Link to="/legal-notice">Impressum</Link>
								<span>&nbsp;|&nbsp;</span>
								<Link to="/legal-notice">Datenschutz</Link>
							</div>
						</div>
					</Transition.Child>
					<div className="w-14 flex-shrink-0">
						{/* Force sidebar to shrink to fit close icon */}
					</div>
				</Dialog>
			</Transition.Root>

			{/* Static sidebar for desktop */}
			<div className="hidden text-white text-xl md:flex md:h-40 md:w-screen md:flex-row md:items-center md:justify-between md:px-36">
				{navigation.left.map(item => navigationItem(item))}
				<a href="/">
					<img
						height="80px"
						width="80px"
						className="aspect-auto h-48 w-auto py-7"
						src="/assets/logo.png"
						alt="logo"
					/>
				</a>
				{navigation.right.map(item => navigationItem(item))}
			</div>
			<div className="flex flex-col md:pl-64">
				<div className="flex items-center justify-between bg-black p-2 md:hidden">
					<button
						type="button"
						className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-white hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ds-yellow"
						onClick={() => setSidebarOpen(true)}
					>
						<span className="sr-only">Open sidebar</span>
						<MenuIcon className="h-6 w-6" aria-hidden="true" />
					</button>
					<div className="text-white">
						<img src="/assets/logo.png" alt="DS-Gesellschaft Logo" className="h-12" />
					</div>
					<div className="invisible w-12 "></div>
				</div>
			</div>
		</>
	);
};

export default Navigation;
