import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import { XIcon } from "@heroicons/react/outline";
import DSGStore from "../DSGStore";

const MessageModal = (props: any) => {
	const initialFocusRef = useRef(null);

	const state = DSGStore.useState();

	return (
		<Transition.Root show={props.open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-10 overflow-y-auto"
				initialFocus={initialFocusRef}
				onClose={props.handleClose}
				open={props.open}
			>
				<div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fade-in-blur fixed inset-0 backdrop-blur-sm backdrop-filter" />
					</Transition.Child>
					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:h-screen sm:align-middle"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block transform overflow-hidden rounded-xl bg-[#0f0f0f] px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6 sm:align-middle">
							<div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
								<button
									type="button"
									className="focus:ring-kartal-turquoise focus:ring-none rounded-md bg-[#0f0f0f] text-white hover:text-gray-300 focus:outline-none"
									onClick={props.handleClose}
								>
									<span className="sr-only">Close</span>
									<XIcon
										className="h-6 w-6"
										aria-hidden="true"
									/>
								</button>
							</div>
							<div className="sm:flex sm:items-start">
								<div className="mt-5 w-full text-center sm:m-2 sm:mt-0 sm:text-left">
									<div className="mt-6 px-6 md:px-14 text-white">
										<h1 className="text-5xl text-ds-yellow">
											{state.messageModalTitle}
										</h1>
										<div className="mt-4">
											{state.messageModalMessage}
										</div>
									</div>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default MessageModal;
