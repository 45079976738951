import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import DSGStore from "../DSGStore";
import { useMediaQuery } from "react-responsive";

const ReferenceModal = (props: any) => {
	const initialFocusRef = useRef(null);
	const [imageIndex, setImageIndex] = useState(0);
	const state = DSGStore.useState();

	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

	const images = state.referenceModalImages;

	const handleNext = () => {
		setImageIndex((previous) => {
			const nextIndex = previous + 1;

			if (nextIndex + 1 > images.length) return 0;
			return nextIndex;
		});
	};

	const handlePrevious = () => {
		setImageIndex((previous) => {
			const nextIndex = previous - 1;

			if (nextIndex < 0) return images.length - 1;
			return nextIndex;
		});
	};

	const pointKeys = state.referenceModalPoints
		.map((point) => point?.split(":")[0])
		.map((point) => `${point}:`);
	const pointValues = state.referenceModalPoints.map(
		(point) => point?.split(":")[1]
	);

	return (
		<Transition.Root show={props.open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-20 overflow-y-auto"
				initialFocus={initialFocusRef}
				onClose={props.handleClose}
				open={props.open}
			>
				<div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 backdrop-blur-sm backdrop-filter" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span
						className="hidden sm:inline-block sm:h-screen sm:align-middle"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block transform overflow-hidden rounded-xl bg-[#0f0f0f] px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-6 sm:align-middle">
							<div className="absolute top-0 right-0 pt-4 pr-4">
								<button
									type="button"
									className="focus:ring-kartal-turquoise focus:ring-none rounded-md bg-[#0f0f0f] text-white hover:text-gray-300 focus:outline-none"
									onClick={props.handleClose}
								>
									<span className="sr-only">Close</span>
									<XIcon
										className="h-6 w-6"
										aria-hidden="true"
									/>
								</button>
							</div>
							<div className="sm:flex sm:items-start">
								<div className="mt-5 w-full text-center sm:m-2 sm:mt-0 sm:text-left">
									<div className="flex items-center space-x-4 text-white">
										<button
											className="cursor-pointer"
											onClick={handlePrevious}
										>
											<ArrowLeftIcon className="h-8 w-8" />
										</button>
										<div className="flex flex-1 w-full aspect-[2/1]">
											<img
												className="rounded-2xl"
												src={images[imageIndex]}
												alt="placeholder"
											/>
										</div>
										<button
											className="cursor-pointer"
											onClick={handleNext}
										>
											<ArrowRightIcon className="h-8 w-8" />
										</button>
									</div>

									<div className="mt-6 px-6 md:px-14 text-white">
										<h1 className="text-3xl text-ds-yellow">
											{state.referenceModalTitle}
										</h1>
										<div className="mt-4 grid grid-cols-5 text-sm md:text-lg">
											{
												isMobile ?
													<>
														<ul className="col-span-5 list-none space-y-2">
															{state.referenceModalPoints.map(
																(point, index) => (
																	<li
																		className="text-left"
																		key={index}
																	>
																		{point}
																	</li>
																)
															)}
														</ul>
													</>
												:
													<>
														<ul className="col-span-2 list-none space-y-2">
															{pointKeys.map(
																(point, index) => (
																	<li
																		className="text-left"
																		key={index}
																	>
																		{point}
																	</li>
																)
															)}
														</ul>
														<ul className="col-span-3 list-none space-y-2">
															{pointValues.map(
																(point, index) => (
																	<li
																		className="text-left"
																		key={index}
																	>
																		{point}
																	</li>
																)
															)}
														</ul>
													</>
											}
										</div>
										<div className="py-6">
											<div className="w-full border-t border-gray-300"></div>
										</div>
										<div className="text-left">
											{state.referenceModalAdditionalText && (
												<>
													<div className="text-lg">
														{
															state.referenceModalAdditionalText
														}
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default ReferenceModal;
