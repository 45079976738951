import { Stock } from "../components/Icons";
import DSGStore from "../DSGStore";

const articles = [
	{
		title: "21.12.2021 - Premiere am Wissenschaftsstandort OST in der sächsischen Landeshauptstadt",
		previewImage: "/assets/press_preview.jpg",
		previewImageAlt: "Beispielbild Gebäude",
		text: (
			<>
				Die Project Gruppe, die als eigenkapitalstarker institutioneller
				Investor & Bestandshalter
				<br />
				im bundesweiten Raum viele Metropolregionen maßgeblich
				mitgestaltet hat,
				<br />
				erwirbt von einem renommierten Dresdner Entwickler ein rd. 8.700
				m² großes Grundstück
				<br />
				am Wissenschaftsstandort OST und ist damit das erste
				Schwergewicht,
				<br />
				welches den aufstrebenden, zukünftig wissenschaftlich geprägten
				Stadtteil mitgestalten wird.
				<br />
				<br />
				Das geplante Wohnquartier wird sich durch seine nachhaltige
				Bauweise, besonders in
				<br />
				den Punkten Elektromobilität und Fernwärme mit höchster
				Energieeffizienz
				<br />
				auszeichnen und bietet auf rd. 12.000 m² Fläche ausreichend
				Platz zum Leben für die
				<br />
				Mitarbeiter der zukünftigen wissenschaftlichen Institute.
				<br />
				<br />
				Entschieden wurde das Rennen um das Grundstück in einem
				Bieterverfahren.
				<br />
				Wichtig war dem Verkäufer, es in die Hände eine langfristigen
				Bestandshalters zu geben
				<br />
				und damit die Stabilität der Region zu fördern.
				<br />
				<br />
				Die Transaktion wurde seit des Vermarktungsbeginns im Januar
				2020 von der DSI -<br />
				Deutsch-Schweizerischen Immobiliengesellschaft begleitet und
				erfolgreich im vierten Quartal 2021 umgesetzt.
			</>
		),
		previewText: (
			<>
				Die Project Gruppe, die als eigenkapitalstarker institutioneller
				Investor & Bestandshalter im bundesweiten Raum viele
				Metropolregionen maßgeblich mitgestaltet hat, erwirbt von einem
				renommierten Dresdner Entwickler ein rd. 8.700 m² großes
				Grundstück am Wissenschaftsstandort OST...
			</>
		),
	},
	{
		title: "01.05.2022 - DSI begleitet Wohnbau-Projekt & Transaktion in der Speckgürtelstadt Freital",
		previewImage: "/assets/press_preview.jpg",
		previewImageAlt: "Beispielbild Gebäude",
		text: (
			<>
				Eine Gruppe von Privatanlegern aus der DACH-Region erwirbt eine
				Wohnanlage mit 48 Wohneinheiten im Speckgürtel von Dresden, in
				der wachsenden großen Kreisstadt Freital.
				<br />
				<br />
				Durch das starke Wachstum der sächsischen Landeshauptstadt
				Dresden gewinnt Freital als unmittelbare Speckgürtelstadt
				zunehmend an Bedeutung und birgt ein großes Potenzial für die
				Zukunft.
				<br />
				<br />
				Das Vorhaben wird von einem regionalen & renommierten Bauträger
				mit jahrzehntelanger Erfahrung aus dem Residential-&
				Healthcarebereich umgesetzt.
				<br />
				<br />
				Die geplante Wohnanlage wird in einer hochwertigen
				Massivbauweise & besonderen Augenmerk auf die Thematik der
				Nachhaltigkeit, mit einer gesamt vermietbaren Fläche in Höhe von
				ca. 3958 m² und einem Wohnungsmix aus familienfreundlichen 3-&
				4-Raum-Wohnungen inkl. einer Tiefgarage errichtet.
				<br />
				<br />
				Darüber hinaus hat die Anlage eine ideale Anbindung an sämtliche
				Nahversorgung, Bildungseinrichtungen sowie an den öffentlichen
				Nahverkehr und liegt fußläufig zum neuen Standort des BSI -
				Bundesamtes für Sicherheit in der Informationstechnik.
				<br />
				<br />
				Besonders hervorsticht auch die effektive & reibungslose
				Zusammenarbeit aller Beteiligten zur Umsetzung des knackigen
				Zeitplans innerhalb von wenigen Monaten von Grundstückskauf bis
				Baubeginn.
				<br />
				<br />
				Die Transaktion wurde von der Grundstücksbeschaffung bis zur
				Veräußerung an die Endkunden von der DSI -
				Deutsch-Schweizerischen Immobiliengesellschaft mbH begleitet.
				<br />
				<br />
				Die DSI - Deutsch-Schweizerische Immobiliengesellschaft mbH ist
				spezialisiert auf die Transaktions- & Projektbegleitung im
				Residential & Healthcarebereich mit Volumen von 10 bis 100
				Millionen Euro/CHF.
			</>
		),
		previewText: (
			<>
				Eine Gruppe von Privatanlegern aus der DACH-Region erwirbt eine
				Wohnanlage mit 48 Wohneinheiten im Speckgürtel von Dresden, in
				der wachsenden großen Kreisstadt Freital.
				<br />
				Durch das starke Wachstum der sächsischen Landeshauptstadt
				Dresden ...
			</>
		),
	},
];

/**
 * props: { title, text, previewImage, previewImageAlt }
 * @returns
 */
const Article = (props: any) => {
	return (
		<div>
			<img
				className="h-[320px] w-full object-cover md:h-[420px]"
				src={props.previewImage}
				alt={props.previewImageAlt}
			/>
			<h2 className="mt-2 font-semibold">{props.title}</h2>
			<p className="h-26 mt-2 md:h-24 lg:h-36 xl:h-32 2xl:h-24">
				{props.text}
			</p>
			<button
				className="mt-4 rounded-lg bg-ds-yellowsend py-2 px-8 text-sm font-normal transition-colors hover:bg-ds-orange"
				onClick={props.clickHandler}
			>
				Mehr Lesen
			</button>
		</div>
	);
};

const Press = () => {
	const handleOpen = (index: number) => {
		const clickedArticle = articles[index];

		DSGStore.update((state) => {
			state.pressModalOpen = true;
			state.pressModalTitle = clickedArticle.title;
			state.pressModalText = clickedArticle.text;
		});
	};

	return (
		<div
			id="press"
			className="relative bg-ds-gray-dark p-6 text-white sm:p-12 sm:pb-20 md:pt-16 xl:px-28 2xl:px-44"
		>
			<div className="z-10 flex flex-col">
				<h1 className="z-10 text-center text-4xl text-ds-yellow md:text-5xl">
					Pressemitteilungen
				</h1>
				<div className="z-10 mt-14 grid grid-cols-1 gap-12 lg:grid-cols-2 xl:grid-cols-2">
					{articles.map((article, index) => (
						<Article
							key={index}
							clickHandler={() => handleOpen(index)}
							title={article.title}
							previewImage={article.previewImage}
							previewImageAlt={article.previewImageAlt}
							text={article.previewText}
						/>
					))}
				</div>
			</div>
			<Stock className="absolute top-0 left-0 rotate-180" />
		</div>
	);
};

export default Press;
